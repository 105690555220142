// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  top: 6px;
  font-size: 10px;
}

.red {
  color: rgba(240, 29, 64, 0.76);
}

.green {
  color: rgba(41, 133, 56, 0.671)
}


.example {
  margin: 12px;
}

Force update ant style
.ant-input {
  padding: 16px 12px 4px 11px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.ant-select .ant-select-selector {
  /* padding: 16px 10px 4px 11px; */
  color: rgba(34, 37, 35, 0.671);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* padding: 16px 10px 4px 11px; */
  height: 34px;
  /* color: blue; */
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
} */
  `, "",{"version":3,"sources":["webpack://./src/aapp/app_one.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,UAAU;EACV,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;AACF;;;AAGA;EACE,YAAY;AACd;;AAEA;;EAEE,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;GAEG","sourcesContent":[".float-label {\n  position: relative;\n  margin-bottom: 12px;\n}\n\n.label {\n  font-size: 12px;\n  font-weight: normal;\n  position: absolute;\n  pointer-events: none;\n  left: 12px;\n  top: 12px;\n  transition: 0.2s ease all;\n}\n\n.label-float {\n  top: 6px;\n  font-size: 10px;\n}\n\n.red {\n  color: rgba(240, 29, 64, 0.76);\n}\n\n.green {\n  color: rgba(41, 133, 56, 0.671)\n}\n\n\n.example {\n  margin: 12px;\n}\n\nForce update ant style\n.ant-input {\n  padding: 16px 12px 4px 11px;\n  margin-top: 7px;\n  margin-bottom: 3px;\n}\n\n.ant-select .ant-select-selector {\n  /* padding: 16px 10px 4px 11px; */\n  color: rgba(34, 37, 35, 0.671);\n}\n\n.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {\n  /* padding: 16px 10px 4px 11px; */\n  height: 34px;\n  /* color: blue; */\n}\n\n/* .ant-select-single .ant-select-selector .ant-select-selection-search {\n  top: 16px;\n} */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
