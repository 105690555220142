import React from "react";
// import { useState } from "react";
import "./css/trnsl.css";
import DrpdwnTrLinks from "./links_tr";


function Trl_wra_wra () {

    // const[userN, suserN] = useState("pdffesx");
    const userN = "pdffesxAA";

    return (
        <>
            <br></br>
            <p>{userN}</p>
            <br></br>
            <DrpdwnTrLinks Al="WA" Bl="WA"/>
            <p>TR  =      WRA   =   WRA</p>
        </>
    );
};


export default Trl_wra_wra;