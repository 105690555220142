import React, { Component } from "react"
// import { Redirect } from "react-router-dom"
// import { useNavigate } from 'react-router-dom';
import axiosInstance from "../AxiosApi"

export default class Login1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            redirect: false,
            msg: "_++_",
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // must need to add async and
    handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const response = await axiosInstance.post("token/obtain/", {
                username: this.state.username,
                password: this.state.password
            })
            axiosInstance.defaults.headers["Authorization"] =
                "Bearer " + response.data.access
            console.log("****************")
            console.log(response)
            console.log("****************")
            console.log(response.data)
            console.log(response.value)
            console.log("****************")
            console.log(response.data.access)
            console.log(response.data.refresh)
            console.log("****111111_")
            const token = response.data.access;
            // Decode token to access extra field
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const extraField = decodedToken.extra_field;
            console.log(extraField)
            const myField = decodedToken.ewew;
            console.log(myField)
            console.log("****111111_")
            console.log(this.state.username)
            console.log(this.state.password)
            console.log(">>>>>>>")
            localStorage.setItem("access_token", response.data.access)
            localStorage.setItem("refresh_token", response.data.refresh)
            localStorage.setItem("username", this.state.username)
            localStorage.setItem("password", this.state.password)
            localStorage.setItem("authTr", true)
            window.dispatchEvent(new Event("storagee"))
            // console.log("login: ", response.data.access)
            this.setState({ redirect: true })
            console.log("9999999___9999999999")
            try {
                const response = await axiosInstance.post("token/inlogout/", {
                    username: this.state.username,
                    password: this.state.password
                })
                console.log("((((((_____****************")
                console.log(response)
                console.log(response.data)
                console.log(response.data.Ghello)
                console.log(response.data.grps)
                console.log(typeof(response.data.grps))
                console.log(typeof(response.data.grps.adm))
                console.log(response.data.grps.adm)
                console.log(response.data.grps[1])

                if (response.data.grps.adm){
                    // console.log("ADM")
                    localStorage.setItem("ug_adm", response.data.grps.adm);
                    window.dispatchEvent(new Event("storage_gr"));
                }
                if (response.data.grps.pru){
                    // console.log("PRU")
                    localStorage.setItem("ug_pru", response.data.grps.pru);
                    window.dispatchEvent(new Event("storage_gr"));
                }
                console.log(localStorage)
                console.log("))))))_____****************")
            } catch (err2){
                console.log("9999999___!!!!!!")
            }
            console.log("9999999___9999999999")
            return response.data
        } catch (error) {
            // throw error;
            this.setState({msg: 'error'});
            this.setState({msg: error.response.status});
            console.log("111_err");
            console.log(error);
            console.log(error.response.data);
            console.log(error.response.status);
            localStorage.clear();
            console.log("111_err");
            // throw error;
            // console.log("222_err");
        }
    }
    // for redirect to home page after successfull login
    rederRedirect = () => {
        if (this.state.redirect) {
            // return <Redirect to="/" />
            // navigate(`/update/${id}`, { replace: true }); // <-- redirect function            
            this.props.navigate('/');
        }
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div style={{
                    "display": "inline-flex", 
                    // "flex-direction": "row",
                    "fontSize":"21px"}}>
                    <label>User Name7</label>
                    <input
                        name="username"
                        type="text"
                        style={{"maxWidth":"195px", "maxHeight":"45px"}}
                        value={this.state.username}
                        onChange={this.handleChange}
                        placeholder="User Name"
                    />
                    <label>Password.</label>
                    <label>{this.state.msg}</label>
                    <input
                        name="password"
                        type="password"
                        style={{"maxWidth":"195px", "maxHeight":"45px"}}
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder="Password"
                    />
                    {/* {this.rederRedirect()} */}
                    <input className="btn-login" type="submit" value="Loginn" 
                        style={{"maxWidth":"195px", "maxHeight":"45px"}}/>
                </div>
            </form>
        )
    }
}
