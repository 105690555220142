import React from "react";
import Loginout1 from "../usr/loginout";

function Top1 () {
    return (
        <>
            <span  style={{"marginLeft":"21px"}}>tttooopppp</span>
            <Loginout1/>
        </>
    )
};

export default Top1;