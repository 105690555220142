import React from "react";
// import { useState } from "react";
import "./css/trnsl.css";
import DrpdwnTrLinks from "./links_tr";


function Trl_wrb_wr () {

    // const[userN, suserN] = useState("pdffesx");
    const userN = "pdffesxB.";

    return (
        <>
            <br></br>
            <p>{userN}</p>
            <br></br>
            <DrpdwnTrLinks Al="WB" Bl="W"/>
            <p>TR  =      WRB   =   WR</p>
        </>
    );
};


export default Trl_wrb_wr;