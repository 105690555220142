import React from "react";
// import { useState } from "react";
import "./css/trnsl.css";
import DrpdwnTrLinks from "./links_tr";


function Trl_wrb_wrb () {

    // const[userN, suserN] = useState("pdffesx");
    const userN ="pdffesxBB";

    return (
        <>
            <br></br>
            <p>{userN}</p>
            <br></br>
            <DrpdwnTrLinks Al="WB" Bl="WB"/>
            <p>TR  =      WRB   =   WRB</p>
        </>
    );
};


export default Trl_wrb_wrb;