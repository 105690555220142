// import React, { useEffect, useState } from "react";
import React from "react";
import { Button } from "antd";
import { Link} from 'react-router-dom';
// import axios from "axios";

function RejestrFrm () {

    return(
        <Link to="/register">
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                rej_
            </Button>
        </Link>
    );
    
    // const [wpDT, setWpdt] = useState([]);

    // const onWpBrd = () => {

    //     console.log("+");
    //     axios.get('http://127.0.0.1:8000/api/aapp/app_two/')
    //     .then(res => {
    //         // console.log("++++");
    //         // console.log(res.data);
    //         // console.log(res.data.results);
    //         setWpdt(res.data);
    //     })
    //     .catch(err => console.log(err));      
    // };

    // return(
    //     <>
    //         <Button onClick={onWpBrd}>T W O</Button>
    //         <div>
    //             {wpDT.map((wpDT, i) => <p key={i}>{wpDT.id} - {wpDT.at_one} - {wpDT.at_ao_one} - {wpDT.at_about} - {wpDT.at_two} - {wpDT.at_three} - {wpDT.at_4_strt} - {wpDT.at_5_done}</p>)}
    //             {/* {wpDT.map((wpDT, i) => <p key={i} style={{paddingLeft:'22px'}}>{   }{wpDT.id} - {wpDT.wp_name} - {wpDT.boards_amount} - {wpDT.wp_project} - {wpDT.prjct}</p>)} */}
    //         </div>
    //     </>
    // );
};

export default RejestrFrm;