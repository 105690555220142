import React, { Component } from "react"
import axiosInstance from "../AxiosApi"

export default class Home extends Component { 
    constructor() {
        super()
        this.state = {
            aboutData: []
        }
    }
    getData = async () => {
        try {
            // console.log(88888888888888);
            const response = await axiosInstance.get("/v1/about/")
            this.setState({
                aboutData: response.data
            })
            console.log(response.data)
            return response.data
        } catch (error) {
            let a=2;
            a=1;
            // console.log("Error_: ", JSON.stringify(error, null, 4))
            // console.log(111111111111111111)
            // throw error
        }
    }

    componentDidMount() {
        const data = this.getData()
        console.log(data)
    }
    render() {
        return (
            <div>
                <div>
                    {this.state.aboutData.map((item) => (
                        <div key={item.id}>
                            <h2>Name: {item.name}...({item.id})</h2>
                            <p>Address: {item.address}</p>
                            <p>Phone Number: {item.phone_no}</p>
                            <br></br>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
