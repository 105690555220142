import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login1 from "./login";
import Logout1 from "./logout";
import Rejestr from "./register";

import { Link} from 'react-router-dom';
import { Button } from "antd";
import RejestrFrm from "./register2";

// import Navbar from "../components/Navbar";
// import Home from "../components/Home";
// import Login from "../components/Login";
// import Signup from "../components/Signup";
// import Logout from "../components/Logout";

function Loginout1() {
    const [logged, setLogged] = useState(false);
    const [unlogged, setUnLogged] = useState(true);
    useEffect(() => {
        if ("username" in localStorage) {
            setLogged(true);
            setUnLogged(false);
        } else {
            setLogged(false);
            setUnLogged(true);
        };
    }, []);
    window.addEventListener('storagee', () => {
        if ("username" in localStorage) {
            setLogged(true);
            setUnLogged(false);
        } else {
            setLogged(false);
            setUnLogged(true);
        };
    });
    useEffect(() => {
        let tt = '';
        tt = tt + logged;
        tt = tt + ",   un - ";
        tt = tt + unlogged;
        // console.log(tt);
        // console.log("!__________!");
        // // localStorage.clear();
      }, [logged]);
      useEffect(() => {
          let tt = '';
          tt = tt + logged;
          tt = tt + ",   un2 - ";
          tt = tt + unlogged;
          console.log(tt);
          console.log("!__________!");
          // localStorage.clear();
        }, [unlogged]);
    return(
        <>
            <span style={{"color": "#22c0da", "fontSize":"31px"}}>
                <span>dffgfgf loginout</span>
                <div style={{
                "display": "inline-flex", 
                // "flex-direction": "row"
                }}>
                    {/* {unlogged && <Rejestr/>} */}
                    {unlogged && <RejestrFrm/>}
                    {unlogged && <Login1/>}
                    {logged && <Logout1/>}
                    {/* {logged && <Rejestr/>} */}
                    {/* <Login1/> */}
                    {/* <Logout1/> */}
                </div>
                {/* <Router>
                    <Navbar />
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/signup">
                            <Signup />
                        </Route>
                        <Route path="/logout">
                            <Logout />
                        </Route>
                    </Switch>
                </Router> */}
            </span>
        </>
    )
};

export default Loginout1;