// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import { Button } from "antd";
import axios from "axios";

function ApTwo () {
    
    const [wpDT, setWpdt] = useState([]);
    const [wpDT1, setWpdt1] = useState([
        {
            id:1,
            at_one: 'BMW',
            at_ao_one: 2022,
            at_about: "ab4r",
            at_two: "tdft",
            at_three: 4543,
            at_4_strt: "gFfsdf",
            at_5_done: true
        },
        {
            id:2,
            at_one: 'Audi',
            at_ao_one: 2023,
            at_about: "f43f4t",
            at_two: "dhfg",
            at_three: 435,
            at_4_strt: "Wfhh",
            at_5_done: false
        },
        {
            id:3,
            at_one: 'Tata',
            at_ao_one: 2012,
            at_about: "630j0j",
            at_two: "htja",
            at_three: 123,
            at_4_strt: "uyWn",
            at_5_done: false
        },
    ]);


    const onWpBrd = () => {

        console.log("+");
        axios.get('http://127.0.0.1:8000/api/aapp/app_two/')
        // axios.get('http://127.0.0.1:8000/api/aapp/app_one/')
        .then(res => {
            console.log("{'<<<'}++++");
            console.log(res.data);
            console.log(res.data.results);
            console.log("++++{'>>>'}");
            setWpdt(res.data);
        })
        .catch(err => console.log(err));      
    };

    return(
        <>
            <Button onClick={onWpBrd}>T W O</Button>
            <Button>T W O__</Button>
            <p>{"<<<<"}</p>
            <div>
                {wpDT1.map((wpDT1, i) => <p key={i}>{wpDT1.id} - {wpDT1.at_one} - {wpDT1.at_ao_one} - {wpDT1.at_about} - {wpDT1.at_two} - {wpDT1.at_three} - {wpDT1.at_4_strt} - {wpDT1.at_5_done}</p>)}
                {wpDT.map((wpDT, i) => <p key={i}>{wpDT.id}{wpDT.id} - {wpDT.at_one} - {wpDT.at_ao_one} - {wpDT.at_about} - {wpDT.at_two} - {wpDT.at_three} - {wpDT.at_4_strt} - {wpDT.at_5_done}</p>)}
                {/* {wpDT.map((wpDT, i) => <p key={i} style={{paddingLeft:'22px'}}>{   }{wpDT.id} - {wpDT.wp_name} - {wpDT.boards_amount} - {wpDT.wp_project} - {wpDT.prjct}</p>)} */}
            </div>
            <p>{">>>>"}</p>
        </>
    );
};

export default ApTwo;