import React from 'react';
import { Routes, Route} from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

import Home from "../components/Home";
import ApOne from "../aapp/app_one";
import ApTwo from "../aapp/app_two";
import RejestrFrmM from '../usr/register';

import Wrd from '../dictn/wrd';
import Wrd_a from '../dictn/wrd_a';
import Wrd_b from '../dictn/wrd_b';

import Trl_wr_wr from '../transl/tr_wr_wr';
import Trl_wr_wra from '../transl/tr_wr_wra';
import Trl_wr_wrb from '../transl/tr_wr_wrb';
import Trl_wra_wr from '../transl/tr_wra_wr';
import Trl_wra_wra from '../transl/tr_wra_wra';
import Trl_wra_wrb from '../transl/tr_wra_wrb';
import Trl_wrb_wr from '../transl/tr_wrb_wr';
import Trl_wrb_wra from '../transl/tr_wrb_wra';
import Trl_wrb_wrb from '../transl/tr_wrb_wrb';


// import Moduls from "../Parts/Module";
// import ModulParts from "../Parts/ModulPart";
// import Parts from "../Parts/Part";
// import Suppliers from "../Parts/Supplier";
// import Cart_parts from '../Stock/Cart_parts';
// import Tmp_02 from "../tmp/tmp_02";
// import Tmp_03 from "../tmp/tmp_03";
// import UsetLissst from "../User/User";
// import WP_part from "../WP/WP_part";
// import WpPartDem from "../WP/WP_part_demands";
// import Prt_upld from '../Upload/PartUpld';
// import MAO_list from '../Plan/MAO';
// import Tmp_04 from '../tmp/tmp_04';
// import Rght_board from '../Board/BoardRgh';
// import Rght_stock from '../Stock/StockRgh';
// import Rght_user from '../User/UserRgh';

function MyRouts () {
    return (
        <>
            <Routes>
                <Route path='/a_home' element={<Home/>} />&nbsp;&nbsp;00
                <Route path='/a_one' element={<ApOne/>} />&nbsp;&nbsp;111
                <Route path='/a_two' element={<ApTwo/>} />&nbsp;&nbsp;22@22
                <Route path='/register' element={<RejestrFrmM/>} />

                <Route path='/dct/wrd' element={<Wrd/>} />
                <Route path='/dct/wrd_a' element={<Wrd_a/>} />
                <Route path='/dct/wrd_b' element={<Wrd_b/>} />



                <Route path='/trl/wrdW.W' element={<Trl_wr_wr/>} />
                <Route path='/trl/wrdW.WA' element={<Trl_wr_wra/>} />
                <Route path='/trl/wrdW.WB' element={<Trl_wr_wrb/>} />

                <Route path='/trl/wrdWA.W' element={<Trl_wra_wr/>} />
                <Route path='/trl/wrdWA.WA' element={<Trl_wra_wra/>} />
                <Route path='/trl/wrdWA.WB' element={<Trl_wra_wrb/>} />

                <Route path='/trl/wrdWB.W' element={<Trl_wrb_wr/>} />
                <Route path='/trl/wrdWB.WA' element={<Trl_wrb_wra/>} />
                <Route path='/trl/wrdWB.WB' element={<Trl_wrb_wrb/>} />


                {/* <Route path='/a_tmp' element={<Tmp_02/>} />&nbsp;&nbsp;44
                <Route path='/a_tmp2' element={<Tmp_03/>} />&nbsp;&nbsp;44
                <Route path='/a_tmp4' element={<Tmp_04/>} />&nbsp;&nbsp;44
                <Route path='/prt_upld' element={<Prt_upld/>} />&nbsp;&nbsp;
                <Route path='/mao' element={<MAO_list/>} />&nbsp;&nbsp;
                <Route path='/WP_part' element={<WP_part/>} />&nbsp;&nbsp;
                <Route exact path='/WP_part_dem' element={<WpPartDem/>} />&nbsp;&nbsp;
                <Route path='/Cart_Part' element={<Cart_parts/>} />&nbsp;&nbsp;
                <Route path='/a11' element={<UsetLissst/>} />&nbsp;&nbsp;44
                <Route path='/a22' element={<Parts/>} />&nbsp;&nbsp;554
                <Route path='/a33' element={<Moduls/>} />&nbsp;&nbsp;554
                <Route path='/a44' element={<ModulParts/>} />&nbsp;&nbsp;554
                <Route path='/a55' element={<Suppliers/>} />&nbsp;&nbsp;554
                <Route path='/a66' element={<Parts/>} />&nbsp;&nbsp;554
                <Route path='/right_board' element={<Rght_board/>} />&nbsp;&nbsp;336
                <Route path='/right_stock' element={<Rght_stock/>} />&nbsp;&nbsp;337
                <Route path='/right_user' element={<Rght_user/>} />&nbsp;&nbsp;338 */}
            </Routes>
        </>
    )
}

export default MyRouts;