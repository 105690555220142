import React, { useEffect, useState } from "react";

function Center1() {
    const [userN, setUserN] = useState('');
    
    window.addEventListener('storagee', () => {
        if ("username" in localStorage) {
            setUserN(localStorage.getItem('username'));
        } else {
            setUserN('');
        };
    });
    
    useEffect(() => {
        if ("username" in localStorage) {
            setUserN(localStorage.getItem('username'));
        } else {
            setUserN('');
        };
    }, []);
    window.addEventListener('storagee', () => {
        console.log("+++++");
        // window.location.reload(false);
        if ("username" in localStorage) {
            setUserN(localStorage.getItem('username'));
        } else {
            setUserN('');
        };
    });

    return (
        <>
            <span>rfe__centre{'<'}</span>
            <p>{userN}</p>
            <span>rfe__centre{'>'}</span>
        </>
    );
};


export default Center1;