    import React from "react";
import { useState, useEffect, useRef, useContext, newState } from "react";
import { Button, Dropdown, message, Table, Form, Input, Modal, Select, Space, TreeSelect, Checkbox, CheckboxProps, Affix } from "antd";
import axios from "axios";
import axiosInstance from "../AxiosApi";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import "./css/trnsl.css";
import { Link, Routes, Route, useNavigate } from "react-router-dom";


function DrpdwnTrLinks ({Al="W", Bl="WA"}) {
    
    const items_110 = [
        {
            link:"W",
            value:"1",
            label:"1st   _ FR",
        },
        {
            link:"WA",
            value:"2",
            label:"2nd   _ IT",
        },
        {
            link:"WB",
            value:"3",
            label:"3rd   _ SW",
        },
    ];

    const navigate = useNavigate();

    const[b2, sB2] = useState(Bl);
    const[b1, sB1] = useState(Al);
    
    const[bAtnnm, sAtnnm] = useState(items_110.find(item => item.link === b1).link);
    const[bBtnnm, sBtnnm] = useState(items_110.find(item => item.link === b2).link);
    
    const[clicke, setClicked] = useState("");

    useEffect(() => {
        sAtnnm(items_110.find(item => item.link === b1).link)
        sBtnnm(items_110.find(item => item.link === b2).link)
        // console.log("Al  Bl", Al, "   ", Bl)
        // // router.push(`/${e.target.value}`);
        // console.log("******  ", b1, " == ", b2);
        const foundItemA = items_110.find(item => item.link === "WB");
        const foundItemB = items_110.find(item => item.link === b2);
        // console.log("^^^^^^  ", foundItemA, " == ", foundItemB);
        // console.log("^^^^^^  ", foundItemA.link, " == ", foundItemB.link);
        // console.log("^^^^^^  ", items_110.find(item => item.link === b2).link);
        // console.log("navigate('trl/wrd" + b1 + "." + b2 +"')");
        // <Link to="/trl/wrdW.WA"></Link>
        navigate("/trl/wrd" + b1 + "." + b2);
    },[b1, b2]);


    const handleChangeA = (value) => {
        // console.log(`A selected ${value}`);
        sB1(items_110[value-1].link);
    };
    
    const handleChangeB = (value) => {
        // console.log(`B selected ${value}`);
        // console.log(`B selected `, items_110[value-1]);
        // console.log(`B selected `, items_110[value-1].link);
        sB2(items_110[value-1].link);
    };

    const items_0 = [
        {
            key: '1',
            link1:"W",
            value:"ONE",
            label: (
                <a>
                    1st   _ FR
                </a>
            ),
        },
        {
            key: '2',
            link1:"WA",
            value:"ONE",
            label: (
                <a value="IT">
                    2nd   _ IT
                </a>
            ),
        },
        {
            key: '3',
            link1:"WB",
            value:"ONE",
            label: (
                <a value="SW">
                    3rd   _ SW
                </a>
            ),
        },
    ];

    const items_1 = [
        {
            key: '1',
            link1:"W",
            value:"TWO",
            label: (
                <a>
                    1st   _ FR
                </a>
            ),
        },
        {
            key: '2',
            link1:"WA",
            value:"TWO",
            label: (
                <a value="IT">
                    2nd   _ IT
                </a>
            ),
        },
        {
            key: '3',
            link1:"WB",
            value:"TWO",
            label: (
                <a value="SW">
                    3rd   _ SW
                </a>
            ),
        },
    ];

    const items_A = [
        {
            key: '1',
            label: (
                // <a target="_blank" rel1="noopener noreferrer" href="/dct/wrd">
                // <a target="_blank" href="/dct/wrd">
                <Link to="/trl/wrdW.WA">
                    1st   _ A  _a
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                // <a href="/dct/wrd_a">
                // {/* <Route path='/dct/wrd_a' element={<ApOne/>} /> */}
                //     2nd
                // </a>
                <Link to="/trl/wrdW.WB">
                    2nd   _ B  _b
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                // <a href="/dct/wrd_b">
                //     3rd
                // </a>
                <Link to="/trl/wrdWA.W">
                    3st   _ C  a_
                </Link>
            ),
        },
    ];

    const items_B = [
        {
            key: '1',
            label: (
                // <a target="_blank" rel1="noopener noreferrer" href="/dct/wrd">
                // <a target="_blank" href="/dct/wrd">
                <Link to="/trl/wrdWA.WB">
                    I   _ A  ab
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                // <a href="/dct/wrd_a">
                // {/* <Route path='/dct/wrd_a' element={<ApOne/>} /> */}
                //     2nd
                // </a>
                <Link to="/trl/wrdWB.W">
                    II   _ B  b_
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                // <a href="/dct/wrd_b">
                //     3rd
                // </a>
                <Link to="/trl/wrdWB.WA">
                    III   _ C  ba
                </Link>
            ),
        },
    ];
    const handleButtonClick = (e) => {
        message.info('Click on left button.');
        console.log('click left button', e);
    };
    const handleMenuClick = (e) => {
      message.info('Click on menu item.');
      console.log('click', e);
    };
    const menuProps = {
      items:items_A,
      onClick: handleMenuClick,
    };

    const handleClick = (e, extraVariable) => {
        e.preventDefault();
        console.log("extraVariable");
        console.log(extraVariable);
      };

    const onClick = ({ key }) => {
        message.info(`Click on item ${key}`);
        console.log('click__', key);
        console.log('click', items_0[key-1]);
        console.log('click', items_0[key-1].link1);
        console.log('click', items_0[key-1].value);
        if (items_0[key-1].value == "ONE") {
            sAtnnm(items_0[key-1].link1);
            console.log("OONNEE");
        } else {
            sBtnnm(items_1[key-1].link1);
            console.log("TTWWOO");
        };
    };
    const TwoCl = () => {
        console.log("ddatataSF");
        // console.log(wpDsf);
        // setDatadaSF(wpDsf.map(object => {return {...object, key: object.id, Parm1: object.id, Parm2: object.ao_one, Parm3: object.ao_two, Parm3_1: object.ao_two, Parm3_2: object.ao_qty, Parm3_diff:false, Parm4: object.ao_dtrt, Parm5: object.ao_three, Parm6: object.ao_fr, Parm6_1: object.ao_fr_1, Parm6_2: object.ao_fr_2, Parm6_diff:false, Parm7: object.ao_sx, Parm8: object.phone_regex, Parm9: object.ao_ph_nmbr, Parm10: object.ao_email, diffQty:false, diffQtyMn:false, diffQtyMx:false}}));
    };

    return (
        <>
            <Select
                // defaultValue="1"
                defaultValue={bAtnnm}
                style={{
                    width: 120,
                }}
                onChange={handleChangeA}
                options={items_110}
            />
            <Select
                // defaultValue="2"
                defaultValue={bBtnnm}
                style={{
                    width: 120,
                }}
                onChange={handleChangeB}
                options={items_110}
            />
        </>
    );
};


export default DrpdwnTrLinks;