import React from "react";

import {Layout} from 'antd';
import { BrowserRouter as Router } from "react-router-dom";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Top1 from "./top";
import Center1 from "./center";
import NavBar from "./navig";
import MyRouts from "./router";

const {Header, Content, Footer, Sider} = Layout;

function Layout1 () {

    return (
        <>
            <Layout style={{height: "100vh", width: "100vw"}}>
                <Router>
                    <Header style={{background:"#35679adf", height:"7vh"}}>
                        2@#
                        <Top1/>
                    </Header>
                    <Layout>
                        <Sider width={160} style={{background:"#95373adf", overflow:"none"}}>
                            1@#
                            <NavBar/>
                        </Sider>
                        <Content style={{overflow:"auto", padding:"20px"}}>
                            3@#
                            <Center1/>
                            <MyRouts/>
                        </Content>
                        <Sider width={30} style={{background:"#35373adf"}}>
                            4@#
                        </Sider>
                    </Layout>                
                </Router>
                <Footer style={{height:"6vh", background:"#a5378adf"}}>
                    5@#
                </Footer>
            </Layout>
        </>
    )
}

export default Layout1;