// import React, { Component, useState } from "react"
import React, {useState,} from "react"
// import { Redirect } from "react-router-dom"
// import { useNavigate } from 'react-router-dom';
import axiosInstance from "../AxiosApi"


function RejestrFrmM () {
    
    const [useraName, setUseraName] = useState("uuugf");
    const [firstName, setFirstName] = useState("nnngtgr");
    const [lastName, setLastName] = useState("LLLL");
    const [age, setAge] = useState(32);
    const [email, setEmail] = useState("ww1@ees.dgt");
    const [passaword,setPassaword] = useState("123123123q!");
    const [confirmPassword,setConfirmPassword] = useState("123123123q!");

    const [passMsg, setPassMsg] = useState("");

    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "useraName"){
            setUseraName(value);
        }
        if(id === "firstName"){
            setFirstName(value);
        }
        if(id === "lastName"){
            setLastName(value);
        }
        if(id === "age"){
            setAge(value);
        }
        if(id === "email"){
            setEmail(value);
        }
        if(id === "passaword"){
            setPassaword(value);
        }
        if(id === "confirmPassword"){
            setConfirmPassword(value);
        }

    }

    const handleSubmit  = async (event) => {
        event.preventDefault();
        console.log("+");
        console.log(useraName, firstName, lastName, age, email, passaword, confirmPassword, "hhh");
        try {
            console.log("#$#$%#$%##");
            const response = await axiosInstance.post("register/", {
                username: useraName,
                password: passaword,
                password2: confirmPassword,
                email: email,
                age: age,
                first_name: firstName,
                last_name: lastName
            });
            console.log("+++==+++");   //123123q!  --  password
            // setUseraName("");
            // setFirstName("");
            // setLastName("");
            // setAge("");
            // setEmail("");
            // setPassaword("");
            // setConfirmPassword("");
            // setPassMsg("");
        } catch (error) {
            console.log("ERRRRRR");
            console.log(error);
            console.log("__1");
            console.log(error.response.data);
            console.log("__2");
            const episodes = error.response.data;
            console.log(episodes[0]);
            console.log(episodes.type);
            console.log(error.response.data.type);
            console.log("__2_!");
            console.log(Object.prototype.toString.call(error.response.data));
            console.log(typeof(error.response.data));
            if (error.response.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    console.log(`${key}: ${value}`);
                    for (const [key1, value1] of Object.entries(value)) {
                        console.log(`${key1}: ${value1}`);
                    }
                }
            }
            console.log("__2_@");
            var secondKey = Object.keys(error.response.data)[0];
            console.log(error.response.data[secondKey]);
            var thirdKey = Object.keys(error.response.data[secondKey])[0];
            console.log(error.response.data[secondKey][thirdKey]);
            var fourthdKey = Object.keys(error.response.data[secondKey])[error.response.data[secondKey].length - 1];
            console.log(error.response.data[secondKey].length);
            console.log(error.response.data[secondKey][fourthdKey]);
            setPassMsg(error.response.data[secondKey][fourthdKey]);

            // const result = {status: 'Success', length: 7, data: {…}};
            // episodes.map(function(item, i){
            //     console.log(item);
            // })            
            console.log("__3");
            console.log(error.response.status);
            console.log("ERRRRRR");
            // throw(error);
        };
    }

    return(
        <>
            <p>grurenue</p>

            {/* <form onSubmit={()=>handleSubmit()}> */}
            <form onSubmit={handleSubmit}>
            
                <div className="form">
                    <div className="form-body">
                        <div className="useraname">
                            <label className="form__label" htmlFor="useraName">User Name </label>
                            <input className="form__input" type="text" value={useraName} onChange = {(e) => handleInputChange(e)} id="useraName" placeholder="User Name"/>
                        </div>
                        <div className="firstname">
                            <label className="form__label" htmlFor="firstName">First Name </label>
                            <input className="form__input" type="text" value={firstName} onChange = {(e) => handleInputChange(e)} id="firstName" placeholder="First Name"/>
                        </div>
                        <div className="lastname">
                            <label className="form__label" htmlFor="lastName">Last Name </label>
                            <input className="form__input" type="text" name="" id="lastName" value={lastName} onChange = {(e) => handleInputChange(e)} placeholder="LastName"/>
                        </div>
                        <div className="age">
                            <label className="form__label" htmlFor="age">Age </label>
                            <input className="form__input" type="number" id="age" value={age} onChange = {(e) => handleInputChange(e)} placeholder="Age"/>
                        </div>
                        <div className="email">
                            <label className="form__label" htmlFor="email">Email </label>
                            <input className="form__input" type="email" id="email" value={email} onChange = {(e) => handleInputChange(e)} placeholder="Email"/>
                        </div>
                        <div className="passaword">
                            <label className="form__label" htmlFor="passaword">Password </label>
                            <input className="form__input" type="password"  id="passaword" value={passaword} onChange = {(e) => handleInputChange(e)} placeholder="Password"/>
                        </div>
                        <div className="confirm-password">
                            <label className="form__label" htmlFor="confirmPassword">Confirm Password </label>
                            <input className="form__input" type="password" id="confirmPassword" value={confirmPassword} onChange = {(e) => handleInputChange(e)} placeholder="Confirm Password"/>
                        </div>
                        <div>
                        <label className="form__label" style={{color: 'red'}}>{passMsg}</label>
                        </div>
                    </div>
                    <div className="footer">
                        <button type="submit" className="btn">Register</button>
                    </div>
                </div>
            </form>

        </>
    );

};

export default RejestrFrmM;

// export default class Rejestr extends Component {
//     constructor() {
//         super()
//         this.state = {
//             username: "",
//             password: "",
//             redirect: false
//         }
//     }
//     handleChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value })
//     }

//     // must need to add async and
//     handleSubmit = async (event) => {
//         event.preventDefault()
//         try {
//             const response = await axiosInstance.post("token/obtain/", {
//                 username: this.state.username,
//                 password: this.state.password
//             })
//             axiosInstance.defaults.headers["Authorization"] =
//                 "Bearer " + response.data.access
//             localStorage.setItem("access_token", response.data.access)
//             localStorage.setItem("refresh_token", response.data.refresh)
//             localStorage.setItem("username", this.state.username)
//             localStorage.setItem("password", this.state.password)
//             localStorage.setItem("authTr", true)
//             window.dispatchEvent(new Event("storagee"))
//             // console.log("login: ", response.data.access)
//             this.setState({ redirect: true })
//             return response.data
//         } catch (error) {
//             throw error
//         }
//     }
//     // for redirect to home page after successfull login
//     rederRedirect = () => {
//         if (this.state.redirect) {
//             // return <Redirect to="/" />
//             // navigate(`/update/${id}`, { replace: true }); // <-- redirect function            
//             this.props.navigate('/');
//         }
//     }
//     render() {
//         return (
//             <form onSubmit={this.handleSubmit}>
//                 <div style={{
//                     "display": "inline-flex", 
//                     // "flex-direction": "row",
//                     "fontSize":"21px"}}>
//                     <label>User Name</label>
//                     <input
//                         name="username"
//                         type="text"
//                         style={{"maxWidth":"195px", "maxHeight":"45px"}}
//                         value={this.state.username}
//                         onChange={this.handleChange}
//                         placeholder="User Name"
//                     />
//                     <label>Password</label>
//                     <input
//                         name="password"
//                         type="password"
//                         style={{"maxWidth":"195px", "maxHeight":"45px"}}
//                         value={this.state.password}
//                         onChange={this.handleChange}
//                         placeholder="Password"
//                     />
//                     {/* {this.rederRedirect()} */}
//                     <input className="btn-login" type="submit" value="Login" 
//                         style={{"maxWidth":"195px", "maxHeight":"45px"}}/>
//                 </div>
//             </form>
//         )
//     }
// }
