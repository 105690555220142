import React from "react";
import { useState, useEffect, useRef, useContext, newState } from "react";
import { Button, Table, Form, Input, Modal, Select, Space, TreeSelect, Checkbox, CheckboxProps, Affix } from "antd";
import axios from "axios";
import axiosInstance from "../AxiosApi";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import "./css/trnsl.css";
import DrpdwnTrLinks from "./links_tr";


function Trl_wr_wr () {

    const[wrd1, sWrd1] = useState([]);
    const[mdlOpenWrd1, sMdlOpenWrd1] = useState(false);
    const[userN, suserN] = useState("pdffesx..");
    const formRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("Nikhil");
    const [inAlert, sInAlert] = useState("");
    const [inWRD_one, sInWRD_one] = useState("c1");
    const [inWRD_two, sInWRD_two] = useState("TRcdc");
    const [inWRD_thr, sInWRD_thr] = useState("wrFEX");
    let inpArray = [inWRD_one, inWRD_two, inWRD_thr];

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // sMdlOpenWrd1(false);
        }, 2000);
    };

    function handleSubmit(event) {
        event.preventDefault();
        sInAlert("");
        if (inpArray.includes("")) {
            console.log("Input is empty");
            sInAlert("Fill all fields");
        } else {
            console.log("Input is not empty");
            createItem();
        }
    };

    const createItem  = async (event) => {
        // event.preventDefault();
        console.log("+");
        console.log(inWRD_one, inWRD_two, inWRD_thr, "hhh");
        try {
            console.log("#$#$%#$%##");
            const response = await axiosInstance.post("dctn/wrd/", {
                aoWR_one: inWRD_one,
                user_create: "uusr1",
                aoWR_two: inWRD_two,
                aoWR_thr: inWRD_thr
            });
            console.log("+++==+++");   //123123q!  --  password
            // POTIM ROZKOMENTUWATY
            // // sInWRD_one("");
            // // sInWRD_two("");
            // // sInWRD_thr("");;
            // // sInAlert("");
            // // sMdlOpenWrd1(false);
            // POTIM ROZKOMENTUWATY
        } catch (error) {
            console.log("ERRRRRR");
            console.log(error);
            console.log("__1");
            console.log(error.response.data);
            console.log("__2");
            const episodes = error.response.data;
            console.log(episodes[0]);
            console.log(episodes.type);
            console.log(error.response.data.type);
            console.log("__2_!");
            console.log(Object.prototype.toString.call(error.response.data));
            console.log(typeof(error.response.data));
            if (error.response.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    console.log(`${key}: ${value}`);
                    for (const [key1, value1] of Object.entries(value)) {
                        console.log(`${key1}: ${value1}`);
                    }
                }
            }
            console.log("__2_@");
            var secondKey = Object.keys(error.response.data)[0];
            console.log(error.response.data[secondKey]);
            var thirdKey = Object.keys(error.response.data[secondKey])[0];
            console.log(error.response.data[secondKey][thirdKey]);
            var fourthdKey = Object.keys(error.response.data[secondKey])[error.response.data[secondKey].length - 1];
            console.log(error.response.data[secondKey].length);
            console.log(error.response.data[secondKey][fourthdKey]);
            sInAlert(error.response.data[secondKey][fourthdKey]);

            // const result = {status: 'Success', length: 7, data: {…}};
            // episodes.map(function(item, i){
            //     console.log(item);
            // })            
            console.log("__3");
            console.log(error.response.status);
            console.log("ERRRRRR");
            // throw(error);
        };
    };

    const onWrd = () => {
        
        axiosInstance.get("/dctn/wrd/")
        // axiosInstance.get("/dctn/wrd/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            console.log("++++ WRD WRD wrd");
            console.log(res.data.results);
            console.log("====================");
            console.log(res.data[0]);
            console.log("====================");
            sWrd1(res.data);
        })
        .catch(err => console.log("err"));

    };

    useEffect(()=> {
        console.log('console.log(wrd1);');
        console.log(wrd1);
        console.log('console.log(wrd1);');
    }, [wrd1]);
    
    const FloatLabel = props => {
        const [focus, setFocus] = useState(false);
        const { children, label, value } = props;
        
        const labelClass =
            focus || (value && value.length !== 0) ? "label label-float" : "label";

        return (
            <div
                className="float-label"
                onBlur={() => ((setFocus(false), console.log("Fb")))}
                onFocus={() => ((setFocus(true), console.log("Ff")))}
            >
                {children}
                <label className={labelClass}>{label}</label>
            </div>
        );
    };

    return (
        <>
            <Modal
                title="Add new word"
                style={{
                    top: 20,
                    right: 14,
                }}
                open={mdlOpenWrd1}
                onOk={() => {
                    // handleSubmit();
                    console.log("!@")
                    formRef.current.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                    );
                    console.log("!@");
                    handleOk();
                    console.log("++");
                }}
                onCancel={() => sMdlOpenWrd1(false)}
            >
                <div className="example">
                    <h5>ExampleWR</h5>
                    <FloatLabel label="First Name" name="firstName1" value={firstName}>
                        <Input value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </FloatLabel>
                    <h5>ExampleWR</h5>
                </div>

                <form style={{justifyContent: "start" }} onSubmit={handleSubmit} ref={formRef}>
                    {/* <div> */}
                    {/* <input type="text" ref={inputRefMod} />
                    <input type="text" ref={inputRefMod2} /> */}
                    <Input type="text" placeholder="Word_1" value={inWRD_one} onChange={e => sInWRD_one(e.target.value)}/>
                    <Input type="text" placeholder="WordTR" value={inWRD_two} onChange={e => sInWRD_two(e.target.value)}/>
                    <Input type="text" placeholder="Word_2" value={inWRD_thr} onChange={e => sInWRD_thr(e.target.value)}/>
                    {/* <button type="submit">Submit</button> */}
                    <label style={{color:"red", marginLeft:"15px"}}>{inAlert}</label>
                    {/* </div> */}
                </form>
            </Modal>
            <br></br>
            <p>{userN}</p>
            <br></br>
            <DrpdwnTrLinks Al="W" Bl="W"/>
            <p>TR  =      WR   =   WR</p>
        </>
    );
};


export default Trl_wr_wr;