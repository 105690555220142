import React, { useEffect, useState } from "react";
import { Button, Input, Select, Modal } from "antd";
import { PlusCircleOutlined } from '@ant-design/icons';
import "./css/trnsl.css";
import axiosInstance from "../AxiosApi";
import DrpdwnTrLinks from "./links_tr";
import { useSearchParams, useParams } from 'react-router-dom';



// import React, { useState } from 'react';
// import { Select } from 'antd';
// import jsonp from 'fetch-jsonp';
import qs from 'qs';
import Inpt_Dct from "./cmpn/input_dic";

function Trl_wr_wra () {
    
    
    const [searchParams, sSearchParams] = useSearchParams();
    const [srchPar_1, sSrchPar_1] = useSearchParams(searchParams.get("wrdul") || '');
    const [srchPar_3, sSrchPar_3] = useState(searchParams.get("wrdew") || '');
    const [srchPar_2, sSrchPar_2] = useState();
    
    useEffect(() => {
        console.log("^^^^^^^^^^^^^^");
        console.log(srchPar_1);
    }, [srchPar_1])
    
    useEffect(() => {
        console.log("^^^^^^^^^^^^^^");
        console.log(srchPar_3);
    }, [srchPar_3])
    
        useEffect(() => {
            PPprapar();
        }, [])

    function ProfilePage() {
        // Get the userId param from the URL.
        let { userId1 } = useParams();
        // ...
    };

    const [searPP, sSeearPP] = useSearchParams();

    function PPprapar() {
        console.log('searchParams.get("wrdul")');
        console.log(searchParams.get("wrdul"));
        console.log(searchParams.get("wlh"));
    };

    const updateUrl = () => {
        sSearchParams({ wrdul: chilVal, wlh: "selectB" });
    };
    
    useEffect(() => {
        PPprapar();
    }, [searchParams])

    useEffect(() => {
        onWrd();
        setTimeout(() => {
            // setLoading(false);
            // setModal1Open(false);
            console.log();
            onWrdAA("PPppP");
        }, 2000);
    }, []);

    const[filteredArrDct, sFilteredArrDct] = useState([]);
    const[valueVS, sValueVS] = useState('');
    const[wrd0, sWrd0] = useState([]);
    const[wrd01, sWrd01] = useState([]);
    const[wrd001AA, sWrd001AA] = useState([]);
    const[wrd001AAVS, sWrd001AAVS] = useState([]);
    const[wrd1, sWrd1] = useState([]);
    const[wrd11, sWrd11] = useState([]);
    const[wrdTR1, sWrdTR1] = useState([]);
    const[mdlOpenWrdTR1, sMdlOpenWrdTR1] = useState(false);
    const[inWRD_one, sInWRD_one] = useState("c1");
    const[inWRD_two, sInWRD_two] = useState("TRcdc");
    const[inAlert, sInAlert] = useState("");
    const formRef = React.useRef(null);
    const[loading, setLoading] = useState(false);
    const[onB, sOnB] = useState(true);
    const[onBI, sOnBI] = useState(0);
    const[onBItxt, sOnBItxt] = useState("Б_");
    let inpArray = [inWRD_one, inWRD_two];
    // const[chilVal, sChilVal] = useState("jjjJj");
    const[chilVal, sChilVal] = useState(searchParams.get("wrdul") || '');
    

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // sMdlOpenWrdTR1(false);
        }, 1000);
    };

    function handleSubmit(event) {
        event.preventDefault();
        sInAlert("");
        if (inpArray.includes("")) {
            console.log("Input is empty");
            sInAlert("Fill all fields");
        } else {
            console.log("Input is not empty");
            createItem();
        }
    };

    const onWrd = () => {        
        axiosInstance.get("/dctn/wrd/"+'?rkop='+onBI+'&rktxt='+onBItxt)
        // axiosInstance.get("/dctn/wrd/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            console.log("++++ onWrd");
            // console.log("++++ WRD WRD wrd");
            // console.log(res.data.results);
            // console.log("====================");
            // console.log(res.data[0]);
            sWrd0(res.data[0]);
        })
        .catch(err => console.log("err"));
    };
    
    const onWrdAA = (ffdf = "ooOO") => {        
        // axiosInstance.get("/dctn/wrd/")
        console.log(ffdf);
        console.log("ffdf");
        axiosInstance.get("/dctn/wrd/"+'?rkop='+3+'&rktxt='+valueVS)
        // axiosInstance.get("/dctn/wrd/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            console.log("++++ onWrdAA");
            // console.log("++++ WRD WRDA trnslt");
            // console.log(res.data.results);
            // console.log("====================");
            // console.log(res.data);
            // console.log(res.data[0]);
            sWrd0(res.data[0]);
        })
        .catch(err => console.log("err"));
    };
    
    useEffect(() => {
        // console.log("&&&&&&&&&===========");
        console.log();
        updtDtdt_0();
    },[wrd0]);

    const updtDtdt_0 = () => {
        sWrd01(wrd0.map(object => {return {...object, key: object.id, Parm1: object.id, Parm2: object.ao_one, Parm3: object.ao_two, Parm3_1: object.ao_two, Parm3_2: object.ao_qty, Parm3_diff:false, Parm4: object.ao_dtrt, Parm5: object.ao_three, Parm6: object.ao_fr, Parm6_1: object.ao_fr_1, Parm6_2: object.ao_fr_2, Parm6_diff:false, Parm7: object.ao_sx, Parm8: object.phone_regex, Parm9: object.ao_ph_nmbr, Parm10: object.ao_email, diffQty:false, diffQtyMn:false, diffQtyMx:false, value: object.id, label: object.w_text}}));
    };
    
    useEffect(() => {
        console.log("&&&&&&&&&$$$$$$$$$$$");
        // console.log(wrd01);
        // if (onB) {
        if (onBI < 4) {
            
            console.log("++++++++++OOOO++++++++++++++");
            sWrd001AA(wrd01);
            sOnB(false);
        };
        sOnBI(onBI + 1);
        // updtDtdt_0();
    },[wrd01]);

    const onWrd_a = () => {        
        axiosInstance.get("/dctn/wrd_a/")
        // axiosInstance.get("/dctn/wrd_a/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            // console.log("++++ WRD WRD wrd_A");
            // console.log(res.data.results);
            // console.log("====================");
            // console.log(res.data[0]);
            // console.log("====================");
            sWrd1(res.data);
        })
        .catch(err => console.log("err"));
    };

    const createItem  = async (event) => {
        // event.preventDefault();
        console.log("+");
        // console.log(inWRD_one, inWRD_two, "hhh");
        try {
            console.log("#$#$%#$%##  <<<");
            const response = await axiosInstance.post("/tr/tr_w_wa/", {
                aoWR_one: inWRD_one,
                user_create: "uusr1",
                aoWR_two: inWRD_two,
            });
            console.log("#$#$%#$%##  >>>");
            // console.log("+++==+++");   //123123q!  --  password
            // POTIM ROZKOMENTUWATY ааа
            // // sInWRD_one("");
            // // sInWRD_two("");
            // // sInWRD_thr("");;
            // // sInAlert("");
            // // sMdlOpenWrdTR1(false);
            // POTIM ROZKOMENTUWATY   ааа
        } catch (error) {
            console.log("ERRRRRR");
            console.log(error);
            console.log("__1");
            console.log(error.response.data);
            console.log("__2");
            const episodes = error.response.data;
            console.log(episodes[0]);
            console.log(episodes.type);
            console.log(error.response.data.type);
            console.log("__2_!");
            console.log(Object.prototype.toString.call(error.response.data));
            console.log(typeof(error.response.data));
            if (error.response.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    console.log(`${key}: ${value}`);
                    for (const [key1, value1] of Object.entries(value)) {
                        console.log(`${key1}: ${value1}`);
                    }
                }
            }
            console.log("__2_@");
            var secondKey = Object.keys(error.response.data)[0];
            console.log(error.response.data[secondKey]);
            var thirdKey = Object.keys(error.response.data[secondKey])[0];
            console.log(error.response.data[secondKey][thirdKey]);
            var fourthdKey = Object.keys(error.response.data[secondKey])[error.response.data[secondKey].length - 1];
            console.log(error.response.data[secondKey].length);
            console.log(error.response.data[secondKey][fourthdKey]);
            sInAlert(error.response.data[secondKey][fourthdKey]);

            // const result = {status: 'Success', length: 7, data: {…}};
            // episodes.map(function(item, i){
            //     console.log(item);
            // })            
            console.log("__3");
            console.log(error.response.status);
            console.log("ERRRRRR");
            // throw(error);
        };
    };

    const onTR_W_Wa = () => {        
        axiosInstance.get("/tr/tr_w_wa/")
        // axiosInstance.get("/dctn/wrd_b/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            console.log("");
            // console.log("++++ WRD WRD wrd_B");
            // console.log(res.data.results);
            // console.log("====================");
            // console.log(res.data[0]);
            // console.log("====================");
            sWrdTR1(res.data);
        })
        .catch(err => console.log("err"));
    };

    // const[userN, suserN] = useState("pdffesx");
    const userN = "pdffesx.A";


    let timeout;
    let currentValue;
    const fetch = (value, callback) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;
        const fake = () => {
            const str = qs.stringify({
                code: 'utf-8',
                q: value,
            });
            // jsonp(`https://suggest.taobao.com/sug?${str}`)
            // .then((response) => response.json())
            // .then((d) => {
            //     if (currentValue === value) {
            //     const { result } = d;
            //     const data = result.map((item) => ({
            //         value: item[0],
            //         text: item[0],
            //     }));
            //     callback(data);
            //     }
            // });
        };
        if (value) {
            timeout = setTimeout(fake, 300);
        } else {
            callback([]);
        }
    };
    const handleChangeVS = (newValue) => {
        console.log("newValue  ", newValue);
        if (typeof newValue === "undefined") {
            // console.log('undfnd');
            sValueVS('');
        } else {
            // console.log('ok new');
            sValueVS(newValue);
        };
        // console.log("#1#1#1#1#1#1#");
    };
    const handleSearchVS = (newValue) => {
        // fetch(newValue, setData);
        sValueVS(newValue);
        console.log("$2$2$2$2$2$2$");
    };
    
    // const filterArrayByParamAndValue = (arr, itemParam, value) => {
    //     return arr.filter(item => item.itemParam === value);      
    // };

    useEffect(() => {
        console.log();
        console.log("filteredArrDct.length___");
        console.log(filteredArrDct);
        console.log(filteredArrDct.length);
        if (filteredArrDct.length < 4 ) {
            onWrdAA("LllLLl");
        };
    },[filteredArrDct]);

    useEffect(() => {
        console.log("");
        // console.log("__==__valueVS__==__");
        // console.log(valueVS);
        // console.log(valueVS.length);
        if (valueVS.length == 0) {
            // console.log("00000000");
            sWrd001AAVS([]);
        } else if (valueVS.length > 0 & valueVS.length < 3) {
            // let gg = filterArrayByParamAndValue(wrd001AAVS, "w_text", valueVS)
            // sFilteredArrDct(wrd001AAVS.filter(item => item.w_text.includes(valueVS)));
            sFilteredArrDct(wrd001AA.filter((item) => item.w_text.includes(valueVS)));
            // console.log("11111111<<<<<");
            // console.log(filteredArrDct);
            // console.log(wrd001AAVS);
            // console.log(wrd001AA);
            sWrd001AAVS(wrd001AA);
            // console.log("11111111");
        } else if (valueVS.length > 2) {
            sWrd001AAVS(wrd001AA);
            // console.log("88888888");
        };
    },[wrd001AA, valueVS]);

    const SearchInput = (props) => {
        const [data, setData] = useState([]);
        const [value, setValue] = useState();
        const handleSearch = (newValue) => {
          fetch(newValue, setData);
        };
        const handleChange = (newValue) => {
          setValue(newValue);
        };
        return (
            <Select
            showSearch
            value={onWrdAA}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.text,
            }))}
            />
        );
    };

    function sttData(datae) {
        sChilVal(datae);
    };

    useEffect(() => {
        updateUrl();
    }, [chilVal])

    return (
        <>
            <br></br>
            <br></br>
            <Inpt_Dct yy="KKL" uyu="DSwD" sndData={sttData} />            
            <br></br>
            <br></br>
            <br></br>{'{{{{{{{'}
            <br></br>
            <Button onClick={updateUrl}>Update URL</Button>
            <Inpt_Dct yy="KKL" uyu="DSwD" sndData={sttData} />
            <p> =+= {chilVal} +=+</p>
            <Modal
                title="Add new translate"
                destroyOnClose={true}
                style={{
                    top: 20,
                    right: 14,
                }}
                open={mdlOpenWrdTR1}
                onOk={() => {
                    // handleSubmit();
                    console.log("!@ tr wwa")
                    formRef.current.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                    );
                    console.log("!@");
                    handleOk();
                    console.log("++");
                }}
                onCancel={() => sMdlOpenWrdTR1(false)}
            >
                <form style={{justifyContent: "start" }} onSubmit={handleSubmit} ref={formRef}>
                    {/* <div> */}
                    {/* <input type="text" ref={inputRefMod} />
                    <input type="text" ref={inputRefMod2} /> */}
                    <Input type="text" placeholder="Word_Orig" value={inWRD_one} onChange={e => sInWRD_one(e.target.value)}
                        style={{marginBottom: "5px"}}
                    />
                    <Select
                        showSearch
                        placeholder="Select a person"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: '1',
                                label: 'Jack',
                            },
                            {
                                value: '2',
                                label: 'Lucy',
                            },
                            {
                                value: '3',
                                label: 'Tom',
                            },
                        ]}
                    />
                    <Select
                        showSearch
                        placeholder="Select a person"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={wrd01}
                    />
                    <Select
                        showSearch
                        placeholder="Select a person"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={wrd001AA}
                    />
                    <Input type="text" placeholder="Word_TRnls" value={inWRD_two} onChange={e => sInWRD_two(e.target.value)}
                        style={{marginTop: "5px"}}
                    />
                    {/* <button type="submit">Submit</button> */}
                    <label style={{color:"red", marginLeft:"15px"}}>{inAlert}</label>
                    {/* </div> */}
                </form>
            </Modal>
            <Button
                onClick={() => (sMdlOpenWrdTR1(true), console.log(9908))}
                type="primary"
                shape="circle"
                // disabled={btndisablecount}
                icon={<PlusCircleOutlined />}
                size={'large'}
                style={{
                    // marginBottom: 16,
                    // marginLeft: 16,
                    position: 'absolute',
                    zIndex: '3',
                    top: '125px',
                    left: '300px',
                }}
            >
            </Button>
            <br></br>
            <p>{userN}</p>
            <DrpdwnTrLinks Al="W" Bl="WA"/>
            <p>TR  =      WR   =   WRA</p>
            <br></br>
            <Button
                onClick={onTR_W_Wa}>
                    Onowl_B
            </Button>
            <br></br>
            <br></br>
            {wrdTR1.map((wrdTR1, i) => <p key={i}>{wrdTR1.id} - {wrdTR1.w_text}{wrdTR1.wa_text}{wrdTR1.wb_text} - {wrdTR1.w_trans}{wrdTR1.wa_trans}{wrdTR1.wb_trans} - {wrdTR1.user_create} - {wrdTR1.date_created}</p>)}

            
            <Select
                showSearch
                value={valueVS}
                placeholder="please input rrr"
                style={{ width: 200, }}
                defaultActiveFirstOption={false}
                allowClear
                suffixIcon={null}
                // filterOption={false}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onSearch={handleSearchVS}
                onChange={handleChangeVS}
                notFoundContent={null}
                options={(wrd001AAVS || []).map((d) => ({
                    value: (d.w_text),
                    label: (d.w_text),
                }))}
            />

            <p>TTTTTTTTTTTTT</p>
            {wrd001AA.map((wrd001AA, i) => <p key={i}>{wrd001AA.id} - {wrd001AA.w_text}{wrd001AA.wa_text}{wrd001AA.wb_text} - {wrd001AA.w_trans}{wrd001AA.wa_trans}{wrd001AA.wb_trans} - {wrd001AA.user_create} - {wrd001AA.date_created}</p>)}
            <p>TTTTTTTTTTTTT</p>
            <p>TTTTTTTTTTTTT</p>
            {wrd01.map((wrd01, i) => <p key={i}>{wrd01.id} - {wrd01.w_text}{wrd01.wa_text}{wrd01.wb_text} - {wrd01.w_trans}{wrd01.wa_trans}{wrd01.wb_trans} - {wrd01.user_create} - {wrd01.date_created}</p>)}
            <p>TTTTTTTTTTTTT</p>
            
            {/* <SearchInput
                placeholder="input search text"
                style={{
                width: 200,
                }}
            /> */}


        </>
    );
};


export default Trl_wr_wra;